import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Dropdown,
  Card,
  Table,
  Form,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import {
  deletesubscription,
  getSubscription,
  insertSubscriptions,
  updatesubscription,
} from "../Services/Subscription.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Subscription() {
  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);
  const [payload, setpayload] = useState({
    title: "",
    cost: 0,
    duration: 0,
    durationType: "",
    description: "",
    jobTypeFilter: true,
    positionFilter: true,
    PayModeFilter: true,
    UnlimitedSwipe: true,
    applicationLimit: 0,
    spotlightsPerWeek: 0,
    backTrackPerWeek: 0,
    invisibleMode: false,
    showAdvertisement: true,
  });
  const reset = () => {
    setid("");
    setpayload({
      title: "",
      cost: 0,
      duration: 0,
      durationType: "",
      description: "",
      jobTypeFilter: true,
      positionFilter: true,
      PayModeFilter: true,
      UnlimitedSwipe: true,
      applicationLimit: 0,
      spotlightsPerWeek: 0,
      backTrackPerWeek: 0,
      invisibleMode: false,
      showAdvertisement: true,
    });
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);

  const [subscription, setsubscription] = useState([]);

  const getdata = async () => {
    setsubscription(await getSubscription());
  };

  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });
  useEffect(() => {
    // if (filter.attribute == "subId") {
    //   setfilterdata(
    //     subscription.filter((item) => {
    //       return item.subId.slice(-5) == filter.value;
    //     })
    //   );
    // } else {
    // }
    setfilterdata(
      subscription.filter((item) => {
        return item[filter.attribute] == filter.value;
      })
    );
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        subscription?.length % 10 == 0
          ? parseInt(subscription?.length / 10)
          : parseInt(subscription?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % 10 == 0
          ? parseInt(filterdata?.length / 10)
          : parseInt(filterdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [subscription]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }
    setTimeout(() => {
      getdata();
    }, 1000);
    getdata();
  }, []);

  const updatedata = async () => {
    const value = await updatesubscription(id, payload);

    if (value.status == "1") {
      toast.success(value.message);
    } else {
      toast.success(value.message);
    }

    setfilter({ ...filter, value: "" });

    getdata();
    reset();
  };
  const deletedata = async () => {
    const data = await deletesubscription(id);
    getdata();
    setfilter({ ...filter, value: "" });
    reset();
  };
  const insertdata = async () => {
    const value = await insertSubscriptions(payload);
    if (value.status == "1") {
      toast.success(value.message);
    } else {
      toast.success(value.message);
    }
    getdata();
    reset();
  };

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>Subscription</h1>
            <Button
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add
            </Button>
          </div>
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ display: "flex" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {filter?.attribute}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ attribute: "title" });
                        }}
                      >
                        Title
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ attribute: "cost" });
                        }}
                      >
                        Cost
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                  <input
                    style={{ marginLeft: "20px" }}
                    type="text"
                    placeholder={filter.attribute}
                    onChange={(e) => {
                      setfilter({ ...filter, value: e.target.value });
                    }}
                  ></input>
                </Col>
                <Col></Col>

                <Col></Col>
              </Row>

              <Table responsive>
                <thead className="thead">
                  <tr>
                    <td>Title</td>
                    <td>Cost</td>

                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {filterdata.length == 0
                    ? subscription
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)

                        ?.map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.title}</td>
                              <td>{item?.cost}</td>

                              <td>
                                <div className="action">
                                  <i
                                    className="icon-edit"
                                    onClick={() => {
                                      setid(item._id);
                                      setpayload(item);

                                      setshownupdatemodal(true);
                                    }}
                                  ></i>

                                  <i
                                    className="icon-trash"
                                    onClick={() => {
                                      setid(item._id);

                                      setshowndeletemodal(true);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    : filterdata
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)

                        ?.map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.title}</td>
                              <td>{item?.cost}</td>

                              <td>
                                <div className="action">
                                  <i
                                    className="icon-edit"
                                    onClick={() => {
                                      setid(item._id);
                                      setpayload(item);

                                      setshownupdatemodal(true);
                                    }}
                                  ></i>

                                  <i
                                    className="icon-trash"
                                    onClick={() => {
                                      setid(item._id);

                                      setshowndeletemodal(true);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                </tbody>
              </Table>

              {filterdata.length == 0 ? (
                <div className="menupagination">
                  Showing{" "}
                  {
                    subscription?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      ?.length
                  }
                  - {subscription?.length} results
                  <div className="pagination">
                    {page == 1 ? (
                      <button disable style={{ cursor: "default" }}>
                        Prev
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page - 1);
                        }}
                      >
                        Prev
                      </button>
                    )}

                    {count?.map((item, index) => {
                      return (
                        <button className={item == page ? "active" : ""}>
                          {item}
                        </button>
                      );
                    })}
                    {page == count?.length ? (
                      <button disable style={{ cursor: "default" }}>
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="menupagination">
                  Showing{" "}
                  {
                    filterdata?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      ?.length
                  }
                  - {filterdata?.length} results
                  <div className="pagination">
                    {page == 1 ? (
                      <button disable style={{ cursor: "default" }}>
                        Prev
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page - 1);
                        }}
                      >
                        Prev
                      </button>
                    )}

                    {count?.map((item, index) => {
                      return (
                        <button className={item == page ? "active" : ""}>
                          {item}
                        </button>
                      );
                    })}
                    {page == count?.length ? (
                      <button disable style={{ cursor: "default" }}>
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>

          <Modal
            show={showndeletemodal}
            onHide={() => {
              reset();

              setshowndeletemodal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="danger"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setid("");

                    setshowndeletemodal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    deletedata();
                    setshowndeletemodal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showncreatemodal || shownupdatemodal}
            onHide={() => {
              reset();

              setshowncreatemodal(false);
              setshownupdatemodal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {showncreatemodal ? "Add" : "Update"} Package
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Title</label>
                    <input
                      name="name"
                      type="text"
                      placeholder="Title"
                      value={payload.title}
                      onChange={(e) =>
                        setpayload({ ...payload, title: e.target.value })
                      }
                    />
                  </Col>

                  <Col className="mb-3">
                    <label>Cost</label>
                    <input
                      name="name"
                      type="Number"
                      placeholder="Cost"
                      value={payload.cost}
                      onChange={(e) =>
                        setpayload({ ...payload, cost: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <label>Duration Type</label>
                    {/* <input
                      name="name"
                      type="Number"
                      placeholder="Weekly Cost"
                      value={payload.durationType}
                      onChange={(e) =>
                        setpayload({ ...payload, durationType : e.target.value })
                      }
                    /> */}
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {payload.durationType
                          ? payload.durationType
                          : "Duration Type"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => {
                            setpayload({ ...payload, durationType: "day" });
                          }}
                        >
                          Day
                        </Dropdown.Item>{" "}
                        <Dropdown.Item
                          onClick={(e) => {
                            setpayload({ ...payload, durationType: "week" });
                          }}
                        >
                          Week
                        </Dropdown.Item>{" "}
                        <Dropdown.Item
                          onClick={(e) => {
                            setpayload({ ...payload, durationType: "month" });
                          }}
                        >
                          Month
                        </Dropdown.Item>{" "}
                        <Dropdown.Item
                          onClick={(e) => {
                            setpayload({ ...payload, durationType: "year" });
                          }}
                        >
                          Year
                        </Dropdown.Item>{" "}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col className="mb-3">
                    <label>Duration</label>
                    <input
                      name="name"
                      type="Number"
                      placeholder="Duration"
                      value={payload.duration}
                      onChange={(e) =>
                        setpayload({ ...payload, duration: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Description</label>
                    <input
                      name="name"
                      type="text"
                      placeholder="description"
                      value={payload.description}
                      onChange={(e) =>
                        setpayload({ ...payload, description: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  {/* <Col>Picture Upload</Col> */}
                  <Col className="mb-3">
                    <label>Pay Mode Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.PayModeFilter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            PayModeFilter: !payload.PayModeFilter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Job Type Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.jobTypeFilter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            jobTypeFilter: !payload.jobTypeFilter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Position Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.positionFilter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            positionFilter: !payload.positionFilter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Application Limit</label>
                    <div className="custom-switch">
                      <input
                        type="number"
                        value={payload.applicationLimit}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            applicationLimit: e.target.value,
                          });
                        }}
                      ></input>
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Spotlights Per Week</label>
                    <div className="custom-switch">
                      <input
                        type="number"
                        value={payload.spotlightsPerWeek}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            spotlightsPerWeek: e.target.value,
                          });
                        }}
                      ></input>
                    </div>
                  </Col>
                  <Col className="mb-3">
                    <label>BackTrack Per Week</label>
                    <div className="custom-switch">
                      <input
                        type="number"
                        value={payload.backTrackPerWeek}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            backTrackPerWeek: e.target.value,
                          });
                        }}
                      ></input>
                    </div>
                  </Col>
                </Row>

                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Un-limited Swipe</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.UnlimitedSwipe}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            UnlimitedSwipe: !payload.UnlimitedSwipe,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Invisible Mode</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.invisibleMode}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            invisibleMode: !payload.invisibleMode,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Show Advertisement</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.showAdvertisement}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            showAdvertisement: !payload.showAdvertisement,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");
                  reset();

                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                }}
              >
                Cancel
              </Button>
              {showncreatemodal ? (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => {
                    setshowncreatemodal(false);
                    setshownupdatemodal(false);
                    insertdata();
                    // createfa(e);
                  }}
                >
                  Create
                </Button>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => {
                    setshowncreatemodal(false);
                    setshownupdatemodal(false);
                    updatedata();
                    // updatefa(id, payload);
                  }}
                >
                  Update
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </>
  );
}
