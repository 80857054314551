import { Action } from "../config/action";

export const Notificationsend = async (payload) => {
  const data = await Action.post(`admin/SendNotification`, payload);
  return data.data;
};

export const NotificationtoAll = async (payload) => {
  const data = await Action.post(`admin/SendNotificationToAll`, payload);

  return data.data;
};
