

// export const imageURL="http://localhost:3090/"

// export const imageURL="https://lookbookstag.jumppace.com:3090/"
export const imageURL="https://lookbook.jumppace.com:3110/"



export const baseURL=`${imageURL}api/v1`

