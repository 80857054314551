import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Dropdown,
  Card,
  Table,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";
import {
  getNotification,
  insertNotification,
  Notificationsend,
  NotificationtoAll,
} from "../Services/Notification.service";
import { getallusers } from "../Services/Users.service";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function Notification() {
  // const [id, setid] = useState("");
  // const [showndeletemodal, setshowndeletemodal] = useState(false);
  // const [showncreatemodal, setshowncreatemodal] = useState(false);
  // const [shownupdatemodal, setshownupdatemodal] = useState(false);
  const [payload, setpayload] = useState({
    title: "",
    body: "",
  });
  const reset = () => {
    // setid("");

    setpayload({
      title: "",
      body: "",
    });
  };
  // const [count, setcount] = useState([]);
  // const [page, setpage] = useState(1);
  // const updatedata = async () => {
  //   const arr = [...notification];
  //   arr[id] = payload;
  //   await insertNotification(arr);
  //   getdata();
  //   reset();
  // };
  // const deletedata = async () => {
  //   var arr = [...notification];
  //   arr.splice(id, 1);
  //   await insertNotification(arr);
  //   getdata();
  //   reset();
  // };

  const [userlist, setuserlist] = useState([]);

  const getdata = async () => {
    const data = await getallusers("?");

    if (data.status) {
      setuserlist(data.data.users.map((item) => ({ ...item, checked: false })));
    } else {
      toast.error(data.message);
    }
  };

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }
    getdata();
  }, []);
  useEffect(() => {}, [userlist]);
  const sendnotification = async () => {
    if (!allchecked) {
      var user_id = userlist.filter((item) => item.checked == true);

      user_id = user_id.map((i) => i._id);

      user_id.map(async (value) => {
        const res = await Notificationsend({
          title: payload.title,
          body: payload.body,
          id: value,
          type: "admin notification",
        });
        console.log(res);
        if (res.status == 0) {
          toast.error(res.message);
        } else {
          toast.success("Notification successfully send");
        }
      });
      setuserlist(userlist.map((item) => ({ ...item, checked: false })));

      // toast.success("Notification successfully send");

      reset();
    } else {
      const data = await NotificationtoAll(payload);

      if (data.status) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      SelectAlluser();
    }
    // if (payload.user_id != "") {
    //   const data = await Notification(payload);
    //   if (data.status == 1) {
    //     toast.success(data.message);
    //   } else {
    //     toast.error(data.message);
    //   }
    //   reset();
    // } else {
    //   toast.error("first Select user");
    // }
  };
  const [allchecked, setChecked] = useState(false);
  const handleCheck = (i) => {
    // var updatedList = [...checked];
    const updateusers = [...userlist];
    updateusers[i].checked = !updateusers[i].checked;
    setuserlist(updateusers);
    // if (checked.indexOf(id) != -1) {
    //   updatedList.splice(checked.indexOf(id), 1);
    // } else {
    //   updatedList = [...checked, id];
    // }

    // setChecked(updatedList);
  };
  const SelectAlluser = async () => {
    setChecked(!allchecked);

    const updateusers = [...userlist];

    setuserlist(updateusers.map((item) => ({ ...item, checked: !allchecked })));
  };
  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <h1>Notification</h1>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <fieldset className="form-group">
                    <label for="basicInput">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title"
                      value={payload.title}
                      onChange={(e) => {
                        setpayload({ ...payload, title: e.target.value });
                      }}
                    />
                  </fieldset>
                </Col>
                <Col>
                  <fieldset className="form-group">
                    <label for="basicInput">Body</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Body"
                      value={payload.body}
                      onChange={(e) => {
                        setpayload({ ...payload, body: e.target.value });
                      }}
                    />
                  </fieldset>
                </Col>
                <Col className="align-self-center">
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      sendnotification();
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <Table>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          className="check"
                          value={allchecked}
                          onChange={(e) => {
                            SelectAlluser();
                          }}
                          checked={allchecked}
                        />
                      </td>
                      <td> Select all</td>
                      <td></td>
                    </tr>
                    {userlist.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            value={item._id}
                            type="checkbox"
                            className="check"
                            onChange={(e) => handleCheck(index)}
                            checked={item.checked}
                          />
                        </td>
                        <td>{item.identifier}</td>
                        <td>
                          {item.userProfile?.firstName +
                            item.userProfile?.lastName}
                        </td>
                      </tr>
                    ))}
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/*         
        <Modal
          show={showndeletemodal}
          onHide={() => {
            reset();

            setshowndeletemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              variant="danger"
              type="submit"
              className="mr-2"
              onClick={(e) => {
                e.preventDefault();
                setid("");

                setshowndeletemodal(false);
              }}
            >
              No
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                deletedata();
                setshowndeletemodal(false);
              }}
            >
              Yes
            </Button>
          </div>

          </Modal.Body>
        </Modal>

        <Modal
          show={showncreatemodal || shownupdatemodal}
          onHide={() => {
            reset();

            setshowncreatemodal(false);
            setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showncreatemodal ? "Add" : "Update"} Order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <div className="inputrow">
                <label>Title</label>
               
                  <input
                    name="name"
                    type="text"
                    placeholder="title"
                    value={payload.title}
                    onChange={(e) =>
                      setpayload({ ...payload, title: e.target.value })
                    }
                  />
                
              
                <label>Description</label>
               
                  <input
                    name="name"
                    type="text"
                    placeholder="description"
                    value={payload.description}
                    onChange={(e) =>
                      setpayload({ ...payload, description: e.target.value })
                    }
                  />
                <div className="d-flex justify-content-between align-items-center mb-3">
                <label>Sender</label>
                
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {payload.senderName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {userlist.map((item) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              setpayload({
                                ...payload,
                                senderName: item?.fullName,
                                senderId: item?.id,
                              });
                            }}
                          >
                            {item.fullName}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
              
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <label>Receiver</label>
                
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {payload.receiverName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {userlist.map((item) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              setpayload({
                                ...payload,
                                receiverName: item.fullName,
                                receiverId: item.id,
                              });
                            }}
                          >
                            {item.fullName}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

             
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setid("");
                reset();

                setshowncreatemodal(false);
                setshownupdatemodal(false);
              }}
            >
              Cancel
            </Button>
            {showncreatemodal ? (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  insertdata();
                  // createfa(e);
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  updatedata();
                  // updatefa(id, payload);
                }}
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Modal> */}
        </Container>
      </div>
    </>
  );
}
