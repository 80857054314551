import { Action } from "../config/action";

// const OrderCollectionRef = collection(db, "Orders");
export const adminLogin= async (payload)=>{
  const data = await Action.post("/adminlogin",payload)
  
  return data.data;
}

export const adminLogout= async ()=>{
  const data = await Action.post("/logout")
  
  return data.data;
}

export const getallusers = async (query) => {
  
  const data = await Action.get(`/admin/users${query}`);
  
  return data.data;
  
};
export const getuserbyquery = async (query)=>{
  

  const data = await Action.get(`/admin/users${query}`);
  
  
  return data.data;
}
export const addUser = async (data) => {
  const Userdata = await Action.post("/signup", data);
  return Userdata.data;
};
export const editUser = async (id, payload) => {
  const data = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => (v != null) & (v != ""))
  );

  const Userdata = await Action.post(`/admin/updateUser/${id}`, data);
  return Userdata.data;
};
export const deleteUser = async (id) => {
  const Userdata = await Action.post(`/admin/userdelete/${id}`);
  return Userdata.data;
};
export const Addusers = async (payload) => {};
