import { Action } from "../config/action";

export const getSubscription = async () => {
  const data = await Action.get(`/subscription/get/all`);
  
    return data.data.data;
  };

  export const deletesubscription = async (id)=>{
    const data = await Action.delete(`/admin/subscription/${id}`)
    return data.data
  }
  export const updatesubscription = async (id,payload)=>{
    const data = await Action.put(`/admin/subscription/${id}`,payload)
    return data.data
  }
  export const insertSubscriptions =async (payload)=>{
    const data = await Action.post(`/admin/subscription`,payload)
    return data.data
  }
  