import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Dropdown,
  Modal,
  Table,
  Card,
  Form,
  Toast,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import {
  addUser,
  deleteUser,
  editUser,
  getallusers,
} from "../Services/Users.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { findAllInRenderedTree } from "react-dom/test-utils";
import { toast } from "react-toastify";
import { imageURL } from "../config/config";
import { Action } from "../config/action";

export default function Users() {
  const params = useParams();

  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);

  const [payload, setpayload] = useState({
    first_name: "user1",
    last_name: "user2",
    user_email: "user123@gmail.com",
    user_password: "12341234  ",
    user_type: "salon",
    user_is_verified: 0,
    notification: "on",
    is_notification: 0,
    user_portfolio: [],
    address: "",
    bussiness_name: "",
    country: "",
    state: "",
    user_info: [],
    user_phone: "",
    zip_postal_code: 0,
    job_type: "",
    user_type: "",
  });
  const reset = () => {
    setid("");
    setpayload({
      first_name: "user1",
      last_name: "user2",
      user_email: "user123@gmail.com",
      user_password: "12341234  ",
      user_type: "salon",
      user_is_verified: 0,
      notification: "on",

      is_notification: 0,
      user_portfolio: [],

      address: "",
      bussiness_name: "",
      country: "",
      state: "",
      user_info: [],
      user_phone: "",
      zip_postal_code: 0,
      job_type: "",
      user_type: "",
    });
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);
  // const [filterdata, setfilterdata] = useState([]);

  const [user, setuser] = useState([]);
  const [usercount, setusercount] = useState(0);
  const getdata = async () => {
    const values = Object.values(filter);
    console.log(values);
    var query = `?page=${page}&limit=10`;

    if (values[0] != "Select any" && values[1] != "") {
      query = query + `&` + values[0] + `=` + values[1];
    }
    const data = await getallusers(query);
    if (data.status) {
      console.log(data.data);
      // data.data?.users?.map((item, index) => {
      //   const titles = item.user_info.map((val) => val.title.title_name);

      //   data.data.users[index].titles = titles;
      // });
      const N =
        data?.data.count % 10 == 0
          ? parseInt(data?.data.count / 10)
          : parseInt(data?.data.count / 10) + 1;

      setcount(Array.from({ length: N }, (_, index) => index + 1));
      setusercount(data.data.count);
      data.data && setuser(data.data);
      data.data &&
        data.data.map((item, index) => {
          const attr = Object.keys(item?.userProfile?.inAppFeatures);
          const inapp = [];
          attr.map((val, i) => {
            if (
              val?.slice(-6) == "Filter" &&
              item?.userProfile?.inAppFeatures[val]
            ) {
              inapp?.push(val);
            }
          });
          data.data[index].userProfile.inappFeatures = inapp.join(" ");
        });
      setuser(data.data);
    } else {
      if (data.message != "No Users Found") {
        toast.error(data.message);
      } else {
        setuser([]);
      }
    }
  };

  // const getdatabyquery = async (query) => {
  //   const data = await getallusers(query);
  //   console.log(query);
  //   if (data.status) {
  //     const N =
  //       data?.data.count % 10 == 0
  //         ? parseInt(data?.data.count / 10)
  //         : parseInt(data?.data.count / 10) + 1;

  //     setcount(Array.from({ length: N }, (_, index) => index + 1));
  //     setusercount(data.data.count);
  //     setfilterdata(data.data.users);
  //   } else {
  //     toast.error(data.message);
  //   }
  // };

  // const updatedata = async () => {
  //   const response = await editUser(id, payload);

  //   if (response.status == 1) {
  //     toast.success(response.message);
  //   } else {
  //     toast.error(response.message);
  //   }
  //   getdata();
  //   reset();
  // };
  const deletedata = async () => {
    const response = await deleteUser(id);
    if (response.status) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }

    getdata();
    reset();
  };
  // const insertdata = async () => {
  //   const data = await addUser(payload);
  //   if (data.status == 1) {
  //     toast.success(data.message);
  //     getdata();
  //   } else {
  //     toast.error(data.message);
  //   }

  //   // reset();
  // };

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
    atttitle: "",
    valtitle: "",
  });

  useEffect(() => {
    // const values = Object.values(filter);
    // console.log(values);
    // if (values[0] != "Select any") {
    //   getdatabyquery(`?${values[0]}=${values[1]}`);
    // }
    getdata();
  }, [filter.value, page]);

  useEffect(() => {
    // if (filterdata.length == 0) {
    //   const N =
    //     user?.length % 10 == 0
    //       ? parseInt(user?.length / 10)
    //       : parseInt(user?.length / 10) + 1;
    //   setcount(Array.from({ length: N }, (_, index) => index + 1));
    // } else {
    //   const N =
    //     filterdata?.length % 10 == 0
    //       ? parseInt(filterdata?.length / 10)
    //       : parseInt(filterdata?.length / 10) + 1;
    //   setcount(Array.from({ length: N }, (_, index) => index + 1));
    // }
  }, [user]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }

    getdata();
  }, []);

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          {/* <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>users</h1>
            <Button
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add
            </Button>
          </div> */}
          <h1>Users</h1>
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ display: "flex" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {filter.atttitle ? filter.atttitle : "Select Attribute"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "first_name" ,title:"Name"});
                        }}
                      >
                        Name
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({
                            ...filter,
                            attribute: "identifier",
                            atttitle: "Email",
                          });
                        }}
                      >
                        Email
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({
                            ...filter,
                            attribute: "isVerified",
                            atttitle: "Verification",
                          });
                        }}
                      >
                        Verification Status
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({
                            ...filter,
                            attribute: "userType",
                            atttitle: "User Type",
                          });
                        }}
                      >
                        User Type
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                  {filter.attribute == "isVerified" ? (
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {filter.valtitle ? filter.valtitle : "Select Value"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          value={0}
                          onClick={(e) => {
                            setfilter({
                              ...filter,
                              value: false,
                              valtitle: "UnVerified",
                            });
                          }}
                        >
                          UnVerified
                        </Dropdown.Item>
                        <Dropdown.Item
                          value={1}
                          onClick={(e) => {
                            setfilter({
                              ...filter,
                              value: true,
                              valtitle: "Verified",
                            });
                          }}
                        >
                          Verified
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : filter.attribute == "userType" ? (
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {filter.valtitle ? filter.valtitle : "Select Value"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          value={0}
                          onClick={(e) => {
                            setfilter({
                              ...filter,
                              value: "worker",
                              valtitle: "Worker",
                            });
                          }}
                        >
                          Worker
                        </Dropdown.Item>
                        <Dropdown.Item
                          value={1}
                          onClick={(e) => {
                            setfilter({
                              ...filter,
                              value: "business",
                              valtitle: "Business",
                            });
                          }}
                        >
                          Business
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <input
                      className="ml-3"
                      type="text"
                      placeholder={filter.attribute}
                      onChange={(e) => {
                        setfilter({ ...filter, value: e.target.value });
                      }}
                    ></input>
                  )}
                </Col>
                <Col></Col>

                <Col></Col>
              </Row>

              <Table className="" responsive>
                <thead className="thead">
                  <tr>
                    <td></td>
                    <td>Full Name</td>
                    <td> Email</td>
                    <td>Profile </td>
                    <td>Status</td>
                    <td>User Type</td>
                    <td>Mile radius</td>
                    <td>User filters</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {user?.length != 0 ? (
                    user?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <img
                              className="profimg"
                              src={
                                imageURL +
                                item.userProfile?.images[
                                  item.userProfile.images.length - 1
                                ]?.file
                              }
                            />
                          </td>
                          <td>
                            {item.userProfile?.firstName +
                              item.userProfile?.lastName}
                          </td>
                          <td>
                            <p>{item?.identifier}</p>
                          </td>
                          <td>
                            <p>
                              {item?.userProfile?.profileCompleted
                                ? "Complete"
                                : "Incomplete"}
                            </p>
                          </td>
                          <td>
                            {item?.isVerified ? "Verified" : "UnVerified"}
                          </td>
                          <td>{item?.userType}</td>
                          <td>{item?.userProfile?.mileRadius}</td>
                          <td>{item?.userProfile?.inappFeatures}</td>
                          <td>
                            <div className="action">
                              {/* <i
                                className="icon-edit"
                                onClick={() => {
                                  setid(item._id);
                                  setpayload(item);
                                  setshownupdatemodal(true);
                                }}
                              ></i> */}

                              <i
                                className="icon-trash"
                                onClick={() => {
                                  setid(item._id);

                                  setshowndeletemodal(true);
                                }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p>No User Found </p>
                  )}
                </tbody>
              </Table>
              <div className="menupagination">
                Showing {user?.length}- {usercount} results
                <div className="pagination">
                  {page == 1 ? (
                    <button disable style={{ cursor: "default" }}>
                      Prev
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}

                  {count?.map((item, index) => {
                    return (
                      <button
                        key={index}
                        className={item == page ? "active" : ""}
                        onClick={() => {
                          setpage(item);
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                  {page == count?.length ? (
                    <button disable style={{ cursor: "default" }}>
                      Next
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page + 1);
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        <Modal
          show={showndeletemodal}
          onHide={() => {
            reset();

            setshowndeletemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="secondary"
                type="submit"
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");

                  setshowndeletemodal(false);
                }}
              >
                No
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  deletedata();
                  setshowndeletemodal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showncreatemodal || shownupdatemodal}
          size="lg"
          onHide={() => {
            reset();

            setshowncreatemodal(false);
            setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showncreatemodal ? "Add" : "Update"} user
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col className="mb-3">
                  {" "}
                  <label>First Name</label>{" "}
                  <input
                    name="name"
                    type="text"
                    placeholder="First Name"
                    value={payload.first_name}
                    onChange={(e) =>
                      setpayload({ ...payload, first_name: e.target.value })
                    }
                  />
                </Col>

                <Col className="mb-3">
                  <label>Last Name</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Last Name"
                    value={payload.last_name}
                    onChange={(e) =>
                      setpayload({ ...payload, last_name: e.target.value })
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <label>Email</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Email"
                    value={payload.email}
                    onChange={(e) =>
                      setpayload({ ...payload, user_email: e.target.value })
                    }
                  />
                </Col>

                <Col className="mb-3">
                  <label>Password</label>
                  <input
                    name="name"
                    type="password"
                    placeholder="password"
                    value={payload.password}
                    onChange={(e) =>
                      setpayload({ ...payload, user_password: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>User Type</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.user_type}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_type: "salon" });
                        }}
                      >
                        Salon
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_type: "stylist" });
                        }}
                      >
                        Stylist
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <Col className="mb-3">
                  <label>Notification</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.notification}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "on" });
                        }}
                      >
                        On
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "off" });
                        }}
                      >
                        Off
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>User Verification status</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.user_is_verified == 1
                        ? "Verified"
                        : "UnVerified"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_is_verified: 0 });
                        }}
                      >
                        UnVerified
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_is_verified: 1 });
                        }}
                      >
                        Verified
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <Col className="mb-3">
                  <label>Job Type</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.job_type}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, job_type: "full_time" });
                        }}
                      >
                        Full Time
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "part_time" });
                        }}
                      >
                        Part Time
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "contract" });
                        }}
                      >
                        Contract
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>Address</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Address"
                    value={payload.address}
                    onChange={(e) =>
                      setpayload({ ...payload, address: e.target.value })
                    }
                  />
                </Col>

                <Col className="mb-3">
                  <label>Zip Postal Code</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="zip postal code"
                    value={payload.zip_postal_code}
                    onChange={(e) =>
                      setpayload({
                        ...payload,
                        zip_postal_code: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <label>User Portfolio</label>
                  <div>
                    {payload.user_portfolio.length > 0
                      ? payload.user_portfolio.map((item) => {
                          return <img src={imageURL + item} />;
                        })
                      : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>User Info</label>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Speciality</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payload.user_info.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.title?.title_name}</td>
                            <td>
                              {item.speciality
                                .map((i) => {
                                  return i.speciality_name;
                                })
                                .join(", ")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setid("");
                reset();

                setshowncreatemodal(false);
                setshownupdatemodal(false);
              }}
            >
              Cancel
            </Button>
            {showncreatemodal ? (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  // insertdata();
                  // createfa(e);
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  // updatedata();
                  // updatefa(id, payload);
                }}
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
